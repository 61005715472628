import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import ButtonComponent from "../../components/ButtonComponent";
import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";

import styles from "./index.module.scss";
import REQUESTS from "../../api/requests";

export default function PricingPage() {
  const [currentPackage, setCurrentPackage] = useState([]);
  const [resellerPackages, setResellerPackages] = useState([]);

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.pricing?.[text]) {
      return translation?.pages?.pricing?.[text];
    }

    return TEXT?.pages?.pricing?.[text];
  };

  const getPackage = () => {
    function callback(data) {
      setCurrentPackage(data.rows);
    }
    function errorCallback() {}

    REQUESTS.GET_PACKAGES(callback, errorCallback);
  };

  const getResellerPackages = () => {
    function callback(data) {
      setResellerPackages(data);
    }
    function errorCallback() {}

    REQUESTS.GET_RESELLER_PACKAGES(callback, errorCallback);
  };

  useEffect(() => {
    getPackage();
    getResellerPackages();
  }, []);

  return (
    <div className={styles["pricing-page"]}>
      <div className={styles["pricing-page-wrapper"]}>
        <p className={styles["info"]}>
          GG Player is a media player for watching a IPTV Services.
        </p>
        <p className={styles["info"]}>
          The app does not provide any content, you must have your own playlist
          to use the app.
        </p>
        <p className={styles["info"]}>
          For activating the app you need to go to activation page and purchase
          the app by Credit Card or PayPal.
        </p>
        <p className={styles["info-price"]}>
          The cost for LifeTime activation is{" "}
          <span className={styles["price"]}>
            {currentPackage && currentPackage[0] && currentPackage[0].price} $
          </span>{" "}
          for a device
        </p>
        <div className={styles["button-link"]}>
          <Link to="/activation">
            <ButtonComponent title={handleText("activate_app")} />
          </Link>
        </div>

        <div className={styles["divider"]}></div>

        <p className={styles["title"]}>{handleText("reseller_packaging")}</p>
        <p className={styles["subtitle"]}>
          If you want to become a reseller,{" "}
          <Link to={"/reseller"} className={styles["register"]}>
            Register
          </Link>{" "}
          and choose a package that fits you best.
        </p>
        <div className={styles["res-packages-container"]}>
          <div className={styles["res-package"]}>
            <div className={styles["name"]}>
              Telegram
              <br />
              @Vitanuova18
            </div>
            <span className={styles["price-block"]}>0 credit for 0$</span>
          </div>
        </div>
        <div className={styles["button-link"]}>
          <Link to="/reseller">
            <ButtonComponent title={handleText("become_a_reseller")} />
          </Link>
        </div>
        <p className={styles["alert"]}>{handleText("alert")}</p>
      </div>
    </div>
  );
}
