import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import "./_referring-pages.scss";
import TEXT from "../../config/text";

import useTranslation from "../../hooks/useTranslation";

export default function TermsOfService() {
  const { appInfo } = useSelector((store) => store.globalState);
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.terms_of_use?.[text]) {
      return translation?.pages?.terms_of_use?.[text];
    }
    return TEXT?.pages?.terms_of_use?.[text];
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <div className="referring-pages">
      <div
        style={{
          marginTop: "60px",
        }}
      >
        <h1>Terms of use</h1>
        <p>
          The application ggplayer.app MediaPlayer is delivered without any
          content. In addition, no content is provided from the application even
          after activating it on our website. The information displayed at the
          beginning includes the MAC address as well as the website so that
          clients can activate the application once for life. The ggplayer.app
          MediaPlayer app has a free 5-days trial period once installed on your
          smart TV to test the features of the app. The MAC address and the URL
          are essential for the proper functioning of the application.
          ggplayer.app MediaPlayer does not provide any content or subscription.
          The content and subscriptions provided under the ggplayer.app
          MediaPlayer ggplayer.app are frauds. ggplayer.app MediaPlayer
          disclaims any responsibility for the use of fraudulent content. The
          application ggplayer.app MediaPlayer or it is entitled can not be held
          as responsible for the quality or interruptions of the content used on
          ggplayer.app MediaPlayer, ggplayer.app MediaPlayer only provides a
          media player. The application ggplayer.app MediaPlayer does not
          provide any advertising for content providers or subscriptions and
          reserves the right to sue anyone who uses our name or logo for
          advertising purposes.
        </p>
        <p>
          After accepting the above conditions, no refund will be granted under
          any circumstances.
        </p>
        <p>
          Note: If you are using a stolen card, your mac will be banned and its
          IP address will be reported.
        </p>
        <p>
          ggplayer.app is owned and operated by ggplayer.app which reserves the
          right to restrict access to the app change its pricing and modify the
          terms of use at any time without the consent of its user. It is the
          user’s responsibility to stay up to date with the terms.
        </p>
      </div>
    </div>
  );
}
