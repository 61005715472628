// import { applyMiddleware, createStore } from "redux";
// import thunk from "redux-thunk";

// import reducers from "./reducers/index";

// const store = createStore(reducers, {}, applyMiddleware(thunk));

// export default store;

import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers/index";

// Enable Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
