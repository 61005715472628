import { API_HOST, HOST } from "../config/index";

const URLS = {
  // a
  APP_INFO: `${API_HOST}app_info`,
  ACTIVATE_VOUCHER_CODE: `${API_HOST}voucher_code_activation`,
  PLAYLIST: `${API_HOST}playlist`,
  PLAYLIST_DELETE: `${API_HOST}palylist_from_web`,
  ADD_DEVICE: `${API_HOST}user/add_device`,
  APPLICATIONS: `${API_HOST}applications`,
  // b
  // c
  CHAT_LOGIN: `${HOST}api/login_by_mac`,
  COUPON_ACTIVATION: `${HOST}api/activate_coupons`,
  CAPTCHA: `${HOST}api/captcha`,
  // d
  DEVICE_INFO: `${API_HOST}device_info`,
  DEVICES: `${API_HOST}user/devices`,
  DELETE_DEVICE: `${API_HOST}user/delete_device`,
  // e
  REGISTRATION_LINK: `${API_HOST}user/reg_link`,
  // f
  // g
  GET_PACKAGES: `${API_HOST}packages`,
  GET_SECRET_KEY: `${HOST}stripe/secret`,
  GET_PUBLISHIN_KEY: `${HOST}stripe/publishing_key`,
  GET_WIDGETS: `${HOST}widgets`,
  GET_OTP: `${API_HOST}otp`,
  GET_PLAYLISTS: `${API_HOST}device`,
  // h
  // i
  IMAGE_UPLOAD: `${HOST}editor/upload`,
  // j
  // k
  // l
  LOGIN_BY_MAC: `${API_HOST}login_by_mac`,
  LANGUAGES: `${HOST}api/languages`,
  LOGIN_MULTIPLE_DEVICES_PAGE: `${API_HOST}user/login`,
  LOGIN_DEVCE: `${API_HOST}user/device_login`,
  // m
  MESSAGES: `${HOST}api/messages`,

  // n
  // o
  // p
  PLAYLIST_WITH_MAC: `${API_HOST}playlist_with_mac`,
  PLAYLIST_VALIDATION: `${HOST}actions/validate_playlist`,
  PAYMENT_SETTINGS: `${HOST}payment/settings`,
  PROTECTED_PLAYLIST: `${API_HOST}protected_playlist`,
  PAYMENT_INFO: `${API_HOST}payment_info`,
  PLAYLIST_DATA: `${API_HOST}playlist_data`,
  GET_NEWS: `${HOST}news`,
  // w
  // x
  // y
  // z
  // q
  // r
  RESELLER: `${HOST}reseller/register`,
  REGISTER: `${API_HOST}user/register`,
  RESELLER_PACKAGES: `${API_HOST}reseller/packages`,
  // s
  SEND_EMAIL: `${HOST}tickets`,
  STRIPE_SECRET_KEY: `${HOST}payment/stripe/paymentintents_secret`,
  // t
  TRANSLATIONS: `${HOST}reseller/translations`,
  // u
  UPLOAD_BY_URL: `${API_HOST}playlist_with_mac`,
  // v
  VALIDATE_MAC: `${API_HOST}validate_mac?mac=`,

  GET_EDITOR_CONFIGS: `${HOST}editor/get_web_settings`,

  UPDATE_EDITOR_CONFIGS: `${HOST}editor/update_web_settings`,

  VALIDATE_TOKEN: `${HOST}admin/validate`,

  // w
  // x
  // y
  // z
};

export default URLS;
