import ERROR from "../images/error.png";
import SUCCESS from "../images/success.png";
import CHAT_BACKGROUND from "../images/chat_bg.svg";
import CMI from "../images/logo_cmi.png";
import NOT_fOUND from "../images/404.png";
import UNDER_CONSRUCTION_PAGE_BG from "../images/under-construction.png";
import UNDER_CONSRUCTION_PAGE_TEXT_1 from "../images/text_1.png";
import UNDER_CONSRUCTION_PAGE_TEXT_2 from "../images/text_2.png";
import SAMSUNG from "../images/samsung.png";
import IOS from "../images/ios.png";
import LG from "../images/lg.png";
import ANDROID from "../images/android.png";
import MainLogo from "../images/Mainlogo.jpg";
import VIDAA from "../images/VIDAA.png";
import HISENSE from "../images/Hisense.png";
import PHILIPS from "../images/philips.png";
import FIRE_TV from "../images/fire.png";
import XIAOMI from "../images/xiaomi.png";
import VIZIO from "../images/vizio.png";
import APPLE from "../images/apple.png";
import PLATFORM from "../images/platforms.png";
import PLATFORM_MOBILE from "../images/platforms_mobile.png";
/**
 * We gather all the images here from the images folder and export them to various locations.
 */

const IMAGES = {
  // a
  ANDROID,
  APPLE,
  // b
  // c
  CHAT_BACKGROUND,
  CMI,
  // d
  // e
  ERROR,
  // f
  FIRE_TV,
  // g
  // h
  HISENSE,
  // i
  IOS,
  // j
  // k
  // l
  LG,
  // m
  MainLogo,
  // n
  NOT_fOUND,
  // o
  // p
  PLATFORM,
  PLATFORM_MOBILE,
  PHILIPS,
  // q
  // r
  // s
  SAMSUNG,
  SUCCESS,
  // t
  // u
  UNDER_CONSRUCTION_PAGE_BG,
  UNDER_CONSRUCTION_PAGE_TEXT_1,
  UNDER_CONSRUCTION_PAGE_TEXT_2,
  // v
  VIZIO,
  VIDAA,
  // w
  // x
  XIAOMI,
  // y
  // z
};

export default IMAGES;
