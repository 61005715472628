import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useTranslation() {
  const [translation, setTranslation] = useState([]);

  // const { webSettings, selectedLanguage } = useSelector((store) => store.globalState);
  const webSettings = useSelector((store) => store.globalState.webSettings);
  const selectedLanguage = useSelector(
    (store) => store.globalState.selectedLanguage
  );

  useEffect(() => {
    if (webSettings && webSettings.translations && selectedLanguage) {
      setTranslation(webSettings.translations[selectedLanguage.iso_code]);
    }
  }, [webSettings, selectedLanguage]);

  return translation;
}
